import html2canvas from "html2canvas";
import jsPDF from "jspdf";

export const downloadPDF = (page,name) => {
    html2canvas(page, {
        useCORS: true, //允许canvas画布内 可以跨域请求外部链接图片, 允许跨域请求。
        allowTaint: true, //允许跨域
        scale: 2, //设置放大倍数
        backgroundColor: '#fff',//背景色 
        useCORS: true
    }).then((canvas)=> {
        // document.body.appendChild(canvas);
        canvas2PDF(canvas,name);   
    })
};

const canvas2PDF = (canvas,name) => {
    // 新建JsPDF对象
    const PDF = new jsPDF({
        orientation: 'p', //参数： l：横向  p：纵向
        unit: 'mm', //参数：测量单位（"pt"，"mm", "cm", "m", "in" or "px"）
        format: 'a4', //A4纸
    })
    const ctx = canvas.getContext('2d')
    const a4w = 190
    const a4h = 277 //A4大小，210mm x 297mm，四边各保留10mm的边距，显示区域190x277
    const imgHeight = Math.floor(a4h * canvas.width / a4w) //按A4显示比例换算一页图像的像素高度
    let renderedHeight = 0

    while (renderedHeight < canvas.height) {
        let page = document.createElement("canvas");
        page.width = canvas.width;
        page.height = Math.min(imgHeight, canvas.height - renderedHeight); //可能内容不足一页

        //用getImageData剪裁指定区域，并画到前面创建的canvas对象中
        page.getContext('2d').putImageData(ctx.getImageData(0, renderedHeight, canvas.width, Math.min(imgHeight, canvas.height - renderedHeight)), 0, 0);

        // canvas转图片数据保留10mm边距
        PDF.addImage(page.toDataURL('image/jpeg', 1), 'JPEG', 10, 10, a4w, Math.min(a4h, a4w * page.height / page.width));

        renderedHeight += imgHeight;

        //判断是否分页，如果后面还有内容，添加一个空页
        if (renderedHeight < canvas.height) {
            PDF.addPage()
        }
    }

    PDF.save(name+".pdf");
    
};
// ***************************https://blog.csdn.net/shanghai597/article/details/134269785***********************